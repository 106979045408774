<template>
  <div class="page">
    <Head :headTitle="headTitle" />
    <div class="b-container">
      <div class="con-l">
        <div class="mod1 flex flex-v">
          <div class="b-pub-tt flex inc">
            <span class="bt">南湖新区介绍</span>
          </div>
          <div class="nhjj">
            <div class="swiper-container swiper mySwiper2">
              <div class="swiper-wrapper">
                <div class="swiper-slide swiper-slide2">
                  <div class="text">
                    南湖新区简介：湖南岳阳洞庭湖旅游度假区位于岳阳市中心城区南部，西傍浩瀚洞庭，东依京珠高速，北连中心城区，南面青山逶迤。<br />
                    区内综合交通体系健全，湘北大道、湖滨大道、求索路、云梦路、龙山路、赶山路行成区间骨干路网，并通过国道、主街道与京港澳高速、岳阳火车站、武广高铁岳阳东站以及新建的岳阳机场相连。
                  </div>
                </div>
                <div class="swiper-slide swiper-slide2">
                  <div class="text">
                    南湖新区简介：湖南岳阳洞庭湖旅游度假区位于岳阳市中心城区南部，西傍浩瀚洞庭，东依京珠高速，北连中心城区，南面青山逶迤。<br />
                    区内综合交通体系健全，湘北大道、湖滨大道、求索路、云梦路、龙山路、赶山路行成区间骨干路网，并通过国道、主街道与京港澳高速、岳阳火车站、武广高铁岳阳东站以及新建的岳阳机场相连。
                  </div>
                </div>
              </div>
              <div class="swiper-scrollbar"></div>
            </div>
            <ul class="mod1-gk">
              <li>
                <img src="../assets/images/wt3.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">常住人口</h6>
                  <p class="num" style="color: #489ffe">10万</p>
                </div>
              </li>
              <li>
                <img src="../assets/images/wt4.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">土地总面积</h6>
                  <p class="num" style="color: #85b1ff">56.18平方千米</p>
                </div>
              </li>
              <li>
                <img src="../assets/images/wt4.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">水域面积</h6>
                  <p class="num" style="color: #90ffad">13.78平方公里</p>
                </div>
              </li>
              <li>
                <img src="../assets/images/wt7.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">湖岸线总长</h6>
                  <p class="num" style="color: #ffc55a">28.2公里</p>
                </div>
              </li>
              <li>
                <img src="../assets/images/wt7.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">年平均气温</h6>
                  <p class="num" style="color: #45f5ff">16度-22度</p>
                </div>
              </li>
              <li>
                <img src="../assets/images/wt7.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">年平均湿度</h6>
                  <p class="num" style="color: #45f5ff">79%</p>
                </div>
              </li>
              <li>
                <img src="../assets/images/wt5.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">街道办事处</h6>
                  <p class="num" style="color: #45f5ff">3个</p>
                </div>
              </li>
              <li>
                <img src="../assets/images/sy_16.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">区直单位</h6>
                  <p class="num" style="color: #45f5ff">29个</p>
                </div>
              </li>
              <li>
                <img src="../assets/images/sy_16.png" class="icon" alt="" />
                <div class="info">
                  <h6 class="tit">市直派驻单位</h6>
                  <p class="num" style="color: #45f5ff">9个</p>
                </div>
              </li>
            </ul>
          </div>

          <div class="swiper-container swiper mySwiper3">
            <div class="swiper-wrapper">
              <div class="swiper-slide swiper-slide1">
                <img
                  class="lazyImg"
                  data-src="../assets/imgs/范蠡归隐.jpg"
                  src=""
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img
                  class="lazyImg"
                  data-src="../assets/imgs/三眼阅秀.jpg"
                  src=""
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img
                  class="lazyImg"
                  data-src="../assets/imgs/天灯引航.jpg"
                  src=""
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img
                  class="lazyImg"
                  data-src="../assets/imgs/圣安塔影.jpg"
                  src=""
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img
                  class="lazyImg"
                  data-src="../assets/imgs/南津古渡.jpg"
                  src=""
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img
                  class="lazyImg"
                  data-src="../assets/imgs/太白赊月.jpg"
                  src=""
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img
                  class="lazyImg"
                  data-src="../assets/imgs/一龙九龟.jpg"
                  src=""
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img
                  class="lazyImg"
                  data-src="../assets/imgs/麦港飞舟.jpg"
                  src=""
                  alt=""
                />
              </div>
              <div class="swiper-slide swiper-slide1">
                <img
                  class="lazyImg"
                  data-src="../assets/imgs/八仙问道.jpg"
                  src=""
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="con-c flex flex-v relative">
        <div class="mod-cen mod3">
          <div class="cen-menu">
            <span class="lk">新时代文明</span>
            <span class="lk">智慧党建</span>
            <span class="lk cur">智慧文旅</span>
            <span class="lk">双中心</span>
          </div>
          <div class="cen-top">
            <li>
              <h6 class="tit">月累计游客(万人)</h6>
              <p class="num">58.53</p>
            </li>
            <li class="line"></li>
            <li>
              <h6 class="tit">年累计游客(万人)</h6>
              <p class="num">702.31</p>
            </li>
            <li class="line"></li>
            <li>
              <h6 class="tit">人均消费(元/人)</h6>
              <p class="num">342.30</p>
            </li>
          </div>
          <div class="mod3-map">
            <div class="yueyang_map"></div>
            <div class="swiper-container swiper-container1">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <img
                    data-src="../assets/imgs/文旅（南津古渡）1.png"
                    src=""
                    alt=""
                    class="lazyImg small_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    data-src="../assets/imgs/文旅（㴩阁寻梅）4.png"
                    src=""
                    alt=""
                    class="lazyImg big_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    data-src="../assets/imgs/文旅（范蠡归隐）5.png"
                    src=""
                    alt=""
                    class="lazyImg small_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    data-src="../assets/imgs/文旅（天灯引航）9.png"
                    src=""
                    alt=""
                    class="lazyImg big_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    data-src="../assets/imgs/文旅（三眼阅秀）6.png"
                    src=""
                    alt=""
                    class="lazyImg small_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    data-src="../assets/imgs/文旅（八仙问道）3.png"
                    src=""
                    alt=""
                    class="lazyImg big_img"
                  />
                </div>

                <div class="swiper-slide">
                  <img
                    data-src="../assets/imgs/文旅（圣安塔影）2.png"
                    src=""
                    alt=""
                    class="lazyImg big_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    data-src="../assets/imgs/文旅（一龙九龟）10.png"
                    src=""
                    alt=""
                    class="lazyImg small_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    data-src="../assets/imgs/文旅（太白赊月）7.png"
                    src=""
                    alt=""
                    class="lazyImg big_img"
                  />
                </div>
                <div class="swiper-slide">
                  <img
                    data-src="../assets/imgs/文旅（麦港飞舟）8.png"
                    src=""
                    alt=""
                    class="lazyImg small_img"
                  />
                </div>
              </div>
            </div>
          </div>
          <ul class="mod3-bot">
            <li>
              <img src="../assets/images/wlv_17.png" class="icon" alt="" />
              <span>景区景点</span>
            </li>
            <li>
              <img src="../assets/images/wlv_18.png" class="icon" alt="" />
              <span>交通接驳</span>
            </li>
            <li>
              <img src="../assets/images/wlv_19.png" class="icon" alt="" />
              <span>住宿</span>
            </li>
            <li>
              <img src="../assets/images/wlv_20.png" class="icon" alt="" />
              <span>餐饮</span>
            </li>
            <li>
              <img src="../assets/images/wlv_21.png" class="icon" alt="" />
              <span>公共服务</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="con-r flex flex-v">
        <div class="mod4 flex-1 flex flex-v">
          <div class="b-pub-tt flex inc">
            <span class="bt">景区展示</span>
          </div>
          <div class="flex flex-pack-justify flex-1">
            <div class="detail">
              <div class="col col1">
                <div class="b-smtit">
                  <span class="bt">㴩阁寻梅</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container2">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="../assets/imgs/㴩阁寻梅.jpg" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="../assets/imgs/㴩阁寻梅.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col2">
                <div class="b-smtit">
                  <span class="bt">范蠡归隐</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container5">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="../assets/imgs/范蠡归隐.jpg" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="../assets/imgs/范蠡归隐.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col3">
                <div class="b-smtit">
                  <span class="bt">天灯引航</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container9">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="../assets/imgs/天灯引航.jpg" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="../assets/imgs/天灯引航.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col4">
                <div class="b-smtit">
                  <span class="bt">三眼阅秀</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container3">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="../assets/imgs/三眼阅秀.jpg" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="../assets/imgs/三眼阅秀.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col5">
                <div class="b-smtit">
                  <span class="bt">八仙问道</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container4">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="../assets/imgs/八仙问道.jpg" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="../assets/imgs/八仙问道.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col6">
                <div class="b-smtit">
                  <span class="bt">南津古渡</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container7">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="../assets/imgs/南津古渡.jpg" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="../assets/imgs/南津古渡.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col7">
                <div class="b-smtit">
                  <span class="bt">圣安塔影</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container8">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="../assets/imgs/圣安塔影.jpg" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="../assets/imgs/圣安塔影.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col8">
                <div class="b-smtit">
                  <span class="bt">一龙九龟</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container6">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="../assets/imgs/一龙九龟.jpg" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="../assets/imgs/一龙九龟.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col9">
                <div class="b-smtit">
                  <span class="bt">太白赊月</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container10">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="../assets/imgs/太白赊月.jpg" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="../assets/imgs/太白赊月.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col10">
                <div class="b-smtit">
                  <span class="bt">麦港飞舟</span>
                </div>
                <div>
                  <div class="swiper-container swiper-container11">
                    <div class="swiper-wrapper">
                      <div class="swiper-slide">
                        <img src="../assets/imgs/麦港飞舟.jpg" alt="" />
                      </div>
                      <div class="swiper-slide">
                        <img src="../assets/imgs/麦港飞舟.jpg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Head from "@/components/head_big.vue";
// let Echarts = require("echarts/lib/echarts");
// import { loopShowTooltip } from "../assets/js/tooltip-auto-show-vue.js";
import Swiper from "swiper";
import "swiper/dist/css/swiper.min.css";
import "swiper/dist/js/swiper.js";
export default {
  data() {
    return {
      // headTitle: "",
      headTitle: "岳阳南湖新区智慧文旅",
      // 月游客
      mVisitor: "",
      // 人均消费
      personCon: "",
      // 年度游客
      yVisitor: "",
      dataList: [
        {
          title: "常驻人口",
          content: "10万",
        },
        {
          title: "土地总面积",
          content: "56.18平方千米",
        },
        {
          title: "水域面积",
          content: "13.78平方公里",
        },
        {
          title: "湖岸线总长",
          content: "28.2公里",
        },
        {
          title: "年平均气温",
          content: "16度-22度",
        },
        {
          title: "年平均湿度",
          content: "79%",
        },
      ],
      swiper1: [],
      swiper2: [],
      swiper3: [],
      swiper4: [],
      swiper5: [],
      swiper6: [],
      swiper7: [],
      swiper8: [],
      swiper9: [],
      swiper10: [],
      swiper11: [],
      mySwiper1: [],
      mySwiper2: [],
    };
  },
  components: {
    Head,
  },
  computed: {},
  created() {},
  mounted() {
    this.init();
    // 获取智慧文旅的信息
    this.getwenlvData();
    this.swiper1 = new Swiper(".swiper-container.swiper-container1", {
      lazyLoading: true,
      lazyLoadingInPrevNext: true,
      lazyLoadingInPrevNextAmount: 2,
      speed: 3000,
      slidesPerView: 1,
      spaceBetween: -10,
      slidesPerColumn: 1,
      autoplay: 10000,
      loop: true,
      centeredSlides: true,
      slidesOffsetAfter: 100,
    });
    this.swiper2 = new Swiper(".swiper-container.swiper-container2", {
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 3000,
      loop: true,
      autoplay: true,
    });
    this.swiper3 = new Swiper(".swiper-container.swiper-container3", {
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 3000,
      loop: true,
      autoplay: true,
    });
    this.swiper4 = new Swiper(".swiper-container.swiper-container4", {
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 3000,
      loop: true,
      autoplay: true,
    });
    this.swiper5 = new Swiper(".swiper-container.swiper-container5", {
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.swiper6 = new Swiper(".swiper-container.swiper-container6", {
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.swiper7 = new Swiper(".swiper-container.swiper-container7", {
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.swiper8 = new Swiper(".swiper-container.swiper-container8", {
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.swiper9 = new Swiper(".swiper-container.swiper-container9", {
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.swiper10 = new Swiper(".swiper-container.swiper-container10", {
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.swiper11 = new Swiper(".swiper-container.swiper-container11", {
      effect: "flip",
      flip: {
        slideShadows: true,
        limitRotation: true,
      },
      speed: 4000,
      loop: true,
      autoplay: true,
    });
    this.mySwiper2 = new Swiper(".swiper-container.mySwiper2", {
      autoplay: {
        // 自动滑动
        stopOnLastSlide: false,
        delay: 10000, //5秒切换一次
        disableOnInteraction: false,
      },
      direction: "vertical",
      grabCursor: true,
      autoplayDisableOnInteraction: false,
      // mousewheelControl:true,
      autoHeight: true,
      observer: true,
      observeParents: true,
      speed: 5000,
      loop: true,
      // scrollbar: ".swiper-scrollbar",
      // direction: "vertical",
      // slidesPerView: "auto",
      // // mousewheelControl: true,
      // // freeMode: true,
      // roundLengths: true,
    });
    this.mySwiper3 = new Swiper(".swiper-container.mySwiper3", {
      // loop: true,
      // speed: 4000,
      // loopedSlides: 4, // looped slides should be the same
      // spaceBetween: 10,
      // centeredSlides: true,
      // slidesPerView: "auto",
      // touchRatio: 0.2,
      // slideToClickedSlide: true,
      // autoplay: true,
      /**
       * slidesPerView : 4,
      spaceBetween : 20,
      speed: 3000,
      autoplay: true,
      loop: true,
       */
      effect: "coverflow",
      slidesPerView: 3,
      centeredSlides: true,
      coverflow: {
        rotate: 30,
        stretch: 10,
        depth: 60,
        modifier: 2,
        slideShadows: true,
      },
      speed: 3000,
      autoplay: true,
      loop: true,
    });
    window.addEventListener("resize", () => {
      // this.chart1.resize();
      // this.chart2.resize();
    });
  },

  methods: {
    init() {
      const imgs = document.getElementsByClassName("lazyImg");
      const viewHeight =
        window.innerHeight || document.documentElement.clientHeight;
      let num = 0;
      function lazyLoad() {
        console.log("滚动....");
        for (let i = num; i < imgs.length; i++) {
          //用可视区域高度减去元素顶部距离可视区域顶部的高度
          let distance = viewHeight - imgs[i].getBoundingClientRect().top;
          console.log("distance...", distance);
          // 如果可视区域高度大于等于元素顶部距离可视区域顶部的高度,说明元素露出
          if (distance >= 0) {
            imgs[i].src = imgs[i].getAttribute("data-src");
            num = i + 1;
          }
        }
      }
      function debouce(fn, delay = 500) {
        let timer = null;
        return function (...args) {
          if (timer) clearTimeout(timer);
          timer = setTimeout(() => {
            fn.call(this, args);
          }, delay);
        };
      }
      window.onload = lazyLoad;
      window.addEventListener("scroll", debouce(lazyLoad, 600), false);
    },
    // 获取智慧文旅的信息
    getwenlvData() {
      const res = axios.get("/10zhwl.json").then(
        (res) => {
          console.log("请求成功了", res.data);
          // this.headTitle=res.data.bigTitle
          this.mVisitor = res.data.mVisitor;
          this.personCon = res.data.personCon;
          this.yVisitor = res.data.yVisitor;
        },
        (err) => {
          console.log("请求失败了", err.message);
        }
      );
    },
  },
};
</script>

<style scoped>
.page {
  background: url(https://media.shouxinfund.com/yueyang/wlv_22.png) no-repeat center;
  background-size: 100% 100%;
}

.mod1 {
  height: 100%;
}
.mod1 .swiper {
  width: 100%;
  margin-top: 2.5%;
}
.mod1 .inc,
.mod4 .inc {
  height: 5%;
}
.mod1 .mySwiper2 {
  height: 25%;
}
.mod1 .mySwiper2 .swiper-slide2 {
  font-size: 48px;
  color: #fff;
  height: auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 30px;
  font-size: 13px;
  font-family: microsoft yahei;
  line-height: 1.8;
}
.mod1 .swiper-slide1 {
  position: relative;
  text-align: center;
  font-size: 18px;
  /* background: #fff; */
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.mod1 .nhjj {
  height: 70%;
  width: 100%;
}
.mod1-gk {
  /* margin-top: 10%; */
  width: 100%;
  height: 20%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* align-items: center; */
}

.mod1-gk li {
  margin-top: 15%;
  width: 33%;
  display: flex;
  align-items: center;
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 20px; */
}

.mod1-gk .icon {
  width: 120px;
  height: 120px;
}
.mod1-gk .info {
  flex: 1;
  overflow: hidden;
}
.mod1-gk .tit {
  font-size: 20px;
  color: #fff;
}
.mod1-gk .num {
  margin-top: 2%;
  font-size: 26px;
  color: #fff;
  font-family: "YouSheBiaoTiHei";
}
.mySwiper2 .swiper-slide1 img {
  border-radius: 50%;
  box-shadow: 0px 10px 10px 10px #8f8282;
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  height: 60%;
  object-fit: cover;
}
.swiper-slide1 .text {
  margin-top: 3%;
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 37%;
  font-size: 32px;
  color: #8f8282;
  letter-spacing: 2px;
  /* border-radius: 50px ;
  border:10px solid #15386e; */
}

.mod1 .mySwiper3 {
  height: 20%;
  /* box-sizing: border-box; */
  /* padding: 10px 0; */
}
.mod1 .mySwiper3 .swiper-slide {
  width: 25%;
}
.mod1 .mySwiper3 .swiper-slide img {
  width: 100%;
  height: 80%;
}
.mod2 {
  height: 55vh;
}
.mod2 .list {
  height: 55vh;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.mod2 .list .item {
  width: 48%;
  height: 22.5vh;
  height: calc(50% - 15px);
}
.mod2 .list .item:nth-child(1),
.mod2 .list .item:nth-child(2) {
  margin-bottom: 20px;
}
.mod2 .list .chart {
  width: 100%;
  height: calc(100% - 50px);
}

.mod2 .fenbu {
  width: 100%;
  height: calc(100% - 50px);
}

.con-c {
  position: relative;
}

.mod3-bot {
  display: flex;
  justify-content: space-around;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 30px;
  padding: 0 4%;
  z-index: 10;
}
.mod3-bot li {
  display: flex;
  align-items: center;
}
.mod3-bot .icon {
  width: 44px;
  margin-right: 16px;
}
.mod3-bot li span {
  font-size: 26px;
  color: #fff;
  font-family: "RuiZiZhenYan";
  letter-spacing: 3px;
}

.mod3-map {
  position: absolute;
  width: calc(100% + 300px);
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  margin-left: -150px;
}
.mod3-map .yueyang_map {
  opacity: 0.6;
  margin: 10% auto;
  width: 80%;
  height: 80%;
  background: url(../assets/imgs/yueyang_map.webp) no-repeat center;
  /* background:url(../assets/imgs/yueyang_map1.png) no-repeat center; */
  background-size: 100% 100%;
}
.mod3-map .swiper-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  width: 100%;
  height: 60%;
  /* background-color: #15386e; */
  overflow: hidden;
  /* margin-top: 50px; */
  width: 80%;
}
.mod3-map .swiper-container .swiper-wrapper {
  margin: 0 auto;
}
.mod3-map .swiper-container .swiper-slide {
  position: relative;
  width: 80%;
  text-align: center;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
}
.mod3-map .swiper-container .swiper-slide .text {
  width: 200px;
  height: 300px;
  position: absolute;
  top: 400px;
  right: 230px;
}
.mod3-map .swiper-container .swiper-slide .big_img {
  width: 95%;
  height: 70%;
  image-rendering: optimizeQuality;
  border: 5px solid #8f8282;
  border-radius: 120px 120px 120px 120px;
}
.mod3-map .swiper-container .swiper-slide .small_img {
  width: 95%;
  height: 70%;
  image-rendering: optimizeQuality;
  border: 2px solid #15386e;
  border-radius: 120px 120px 120px 120px;
}
.mod4 {
  height: 100%;
}
.mod4 .detail {
  width: 95%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.mod4 .col {
  width: 50%;
  height: 20%;
  /* display: flex;
  flex-direction: column; */
}
.mod4 .col .swiper-container {
  width: 50%;
  height: 50%;
}
.mod4 .detail .swiper-container .swiper-slide {
  border: 2px solid #15386e;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.mod4 .detail .swiper-container .swiper-slide img {
  width: 100%;
  /* height: 100%; */
  height: 200px;
  border-radius: 10px;
}
.mod4 .col1 .bar-list .font1,
.mod4 .col2 .bar-list .font1 {
  display: flex;
  justify-content: space-around;
}
.mod4 .list {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(3, auto);
  grid-template-rows: repeat(3, auto);
  grid-gap: 15px;
}
.mod4 .list .item {
  position: relative; /* width: 185px;height: 160px; */ /* margin:0 0 25px 15px; */
}
.mod4 .list .title {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #15386e;
  padding: 5px 5px;
  line-height: 24px;
  text-align: center;
  font-size: 14px;
  color: #fff;
}

/* .mod5 {
  height: 14vh;
}
.mod5 .b-pub-tt {
  margin-bottom: 10px;
}
.mod5 .list {
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
}
.mod5 .list li {
  display: flex;
  align-items: center;
}
.mod5 .list .xh {
  width: 100px;
  margin-right: 5px;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "PangMenZhengDao";
  font-size: 28px;
  color: #fff;
}
.mod5 .list .info {
  flex: 1;
  overflow: hidden;
}
.mod5 .list .tit {
  font-size: 22px;
  color: #fff;
}
.mod5 .list .num {
  font-size: 26px;
  color: #fff;
  font-family: "PangMenZhengDao";
  margin-top: 5px;
}

.mod5 .list .item1 .xh {
  background-image: url(../assets/images/wlv_13.png);
}
.mod5 .list .item2 .xh {
  background-image: url(../assets/images/wlv_14.png);
}
.mod5 .list .item3 .xh {
  background-image: url(../assets/images/wlv_15.png);
}
.mod5 .list .item4 .xh {
  background-image: url(../assets/images/wlv_16.png);
}
.mod5 .list .item5 .xh {
  background-image: url(../assets/images/wlv_23.png);
} */

.mod6 {
  height: 30%;
}
.mod6 .col {
  width: 46%;
}
.mod6 col .bar-list .smbox .font1 {
  display: flex;
  justify-content: space-around;
}
</style>
